<template>
  <div :style="{ 'max-height': $store.getters.maxPageHeight + 'px' }">
    <div :style="{
      'overflow-y': 'auto',
      'max-height': $store.getters.maxPageHeight + 'px',
    }">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  computed: {
    ...mapGetters("appUser", ["authModules", "currentModuleID"]),
    ...mapGetters(["appSettings"]),
    currentModel() {
      var t = this.authModules.find((v) => {
        return v.SysID == this.SystemKey;
      });
      if (t) return this.onFindRoute(t, this.$route.name);

      return null;
    },
    ...mapState("MultiPageBar", ["PageList", "CurrentPage"]),
    keepRoutes() {
      var rs = [];
      this.PageList.forEach((e) => {
        rs.push(e.name);
      });
      return rs;
    },
  },
  methods: {
    onFindRoute(r, rname) {
      if (r.RouteName == rname) return r;
      else if (r.Children.length > 0) {
        for (var i = 0; i < r.Children.length; i++) {
          var temp = this.onFindRoute(r.Children[i], rname);
          if (temp) return temp;
        }
      }
      return null;
    },
  },
};
</script>
